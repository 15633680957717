import React from 'react'
import styles from './BrigthPlanCard.module.css'
import { IoCheckmarkCircleSharp } from "react-icons/io5";

const BrigthPlanCard = ({ month, price, unit, domain, analytics }) => {
    return (
        <div className={styles.card}>
            <div className={styles.titles}>
                <p className={styles.date}>
                    {month} сар
                </p>
                <div style={{ gap: '5px', alignItems: 'start' }} className="row">
                    <h2 >₮{price}</h2>
                    <p style={{ fontSize: '12px', paddingTop: '8px' }}>/сар</p>
                </div>
                <p style={{ fontSize: '13px' }}>Туршилтанд хамгийн шилдэг</p>
            </div>
            <div className="divider" style={{ width: window.innerWidth > 720 ? '15vw' : '60vw', marginTop: '20px', borderColor: '#5d009f' }}></div>
            <div className={styles.contents}>
                <div className={styles.content}>
                    <IoCheckmarkCircleSharp color='#5d009f' size={25} />
                    <p>Веб түрээс</p>
                </div>
                <div className={styles.content}>
                    <IoCheckmarkCircleSharp color='#5d009f' size={25} />
                    <p>QR нэрийн хуудас / {unit}ш /</p>
                </div>
                <div className={styles.content} >
                    <IoCheckmarkCircleSharp color='#5d009f' size={25} />
                    <p>Туслах үйлчилгээ</p>
                </div>
                <div className={styles.content} style={{ display: domain ? '' : 'none' }}>
                    <IoCheckmarkCircleSharp color='#5d009f' size={25} />
                    <p>.com Домэйн үнэгүй</p>
                </div>
                <div className={styles.content} style={{ display: analytics ? '' : 'none' }}>
                    <IoCheckmarkCircleSharp color='#5d009f' size={25} />
                    <p>Анализ, дүн шинжилгээ</p>
                </div>
            </div>
            <button className={styles.button}>
                Захиалах
            </button>
        </div>
    )
}

export default BrigthPlanCard
