import React from 'react'
import styles from './Categorydetial.module.css'
import WebCard from '../../../Utills/WecCard/WebCard';
import Navbar from '../../../Components/Navbar/Navbar';
import { useParams } from 'react-router-dom';

import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';

const CategoryDetial = ({ templates, sliders }) => {
    const { id } = useParams();


    const handleSlideChange = () => {
        console.log('slide change');
    };

    return (
        <div style={{ background: '#f0f0f0' }}>
            <Navbar />
            <div className='container'>
                <div className="slider">
                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={0}
                        slidesPerView={1}
                        className='swiper'
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: true,
                        }}
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={handleSlideChange}
                    >
                        {sliders.map((e) => (
                            <SwiperSlide className='swiper'>
                                <Link className='swiper'>
                                    <img src={'https://template.templateapi.xyz/uploads/' + e.cover} alt="" />
                                    <p></p>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
                <div className={styles.categories_list}>
                    <div>
                        {templates.filter((e) => e.category === id).map((e) => (
                            <div className={styles.category_item}>
                                <WebCard title={e.name} id={e._id}
                                    img={"https://template.templateapi.xyz/uploads/" + e.cover}
                                />
                            </div>
                        ))}
                    </div>
                    {/* </div> */}
                </div>
            </div>
        </div >
    )
}

export default CategoryDetial
