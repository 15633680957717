import React from 'react'
import styles from './Navbar.module.css'

const Navbar = () => {
    const user = window.localStorage.getItem('user');

    return (
        <div className={styles.container} style={{ zIndex: 9 }} >
            <p>Нүүр хуудас</p>
            {user !== null ?
                <p className={styles.logout}
                    onClick={() => {
                        window.localStorage.removeItem('user');
                        window.location.reload(false);
                    }
                    }
                >
                    Гарах
                </p> : <div></div>
            }
        </div >
    )
}

export default Navbar
