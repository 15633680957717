import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './Components/Sidebar/Sidebar';
import Home from './Pages/Home/Home';
import WebDetial from './Pages/Detial/WebDetial/WebDetial';
import { useEffect, useState } from 'react';
import axios from 'axios';
import CategoryDetial from './Pages/Detial/CategoryDetial/CategoryDetial';
import Profile from './Pages/Profile/Profile';

function App() {
  const [categories, setCategories] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [sliders, setSliders] = useState([]);
  const [loading, setLoading] = useState(true);

  const url = 'https://template.templateapi.xyz';

  useEffect(() => {
    if (loading) {
      Promise.all([
        axios.get(url + '/categories'),
        axios.get(url + '/templates'),
        axios.get(url + '/slider')
      ])
        .then(([categoriesRes, templatesRes, slidersRes]) => {
          setCategories(categoriesRes.data.data);
          setTemplates(templatesRes.data.data);
          setSliders(slidersRes.data.data);
          setLoading(false);
        })
        .catch((e) => alert(e));
    }
  }, [loading]);

  if (loading) {
    return <div className="loader-container"><div className="loader"></div></div>;
  }
  return (
    <>
      <Router>
        <Sidebar categories={categories} />
        <Routes>
          {/* <Route path='/' element={<Login />} /> */}
          <Route path='/' element={<Home sliders={sliders} templates={templates} categories={categories} />} />
          <Route path='/webdetial/:id' element={<WebDetial templates={templates} />} />
          <Route path='/categorydetial/:id' element={<CategoryDetial sliders={sliders} templates={templates} categories={categories} />} />
          <Route path='/profile' element={<Profile />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
