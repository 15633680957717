import axios from 'axios';
import React, { useState } from 'react'

const Signup = ({ closeModalSignUp }) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        password: ''
    });
    const [file, setFile] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        for (const key in formData) {
            console.log(formData[key]);
            data.append(key, formData[key]);
        }

        data.append('file', file[0]);

        // console.log(data);

        try {
            axios.post('https://template.templateapi.xyz/user/register', data)
                .then(() => window.location.reload(false))
                .catch((e) => console.log(e));
        } catch (error) {
            console.error('Error:', error);
            alert('An error occurred. Please try again.');
        }
    };

    return (
        <div className='blur_container'>
            <div className="modal" style={{ width: window.innerWidth > 640 ? '20vw' : '80vw' }}>
                <div className="two-line" style={{ width: window.innerWidth > 640 ? '20vw' : '80vw' }}>
                    <h2>Бүртгүүлэх</h2>
                    <h2 onClick={closeModalSignUp} className="x">X</h2>
                </div>
                <label>
                    Нэр:
                    <input type="text" placeholder='Нэр' onChange={handleChange} name="name" id="" />
                </label>
                <label>
                    И-мэйл:
                    <input type="text" placeholder='И-мэйл' onChange={handleChange} name="email" id="" />
                </label>
                <label>
                    Утасны дугаар:
                    <input type="number" placeholder='Дугаар' onChange={handleChange} name="phone" id="" />
                </label>
                <label>
                    Нүүр зураг:
                    <input type="file" placeholder='Нэр' onChange={(e) => setFile(e.target.files)} name="file" id="" />
                </label>
                {formData.cover && <img src={formData.cover ? URL.createObjectURL(formData.cover) : ''} alt="" style={{ width: '200px', height: '200px' }} />}
                <label>
                    Нууц үг:
                    <input type="password" placeholder='Нууц үг' onChange={handleChange} name="password" id="" />
                </label>
                <div className="two-line" style={{ width: '20vw' }}>
                    <button onClick={handleSubmit} className="white-button">Хадгалах</button>
                    <button onClick={closeModalSignUp} className="button" style={{ backgroundColor: 'red', color: '#fff' }}>Буцах</button>
                </div>
            </div>
        </div>
    )
}

export default Signup
