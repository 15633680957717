import React from 'react'
import styles from './Profile.module.css';
import phone from '../../Assets/Profile/Phone.svg'
import mail from '../../Assets/Profile/Mail.svg'
import { GoDotFill } from "react-icons/go";
import { IoAddCircleOutline } from "react-icons/io5";

const Profile = () => {
    return (
        <div className={styles.container}>
            <div className={styles.info}>
                <img src="https://t4.ftcdn.net/jpg/03/26/98/51/360_F_326985142_1aaKcEjMQW6ULp6oI9MYuv8lN9f8sFmj.jpg" className={styles.profile} alt="" />
                <div className={styles.infocard} style={{ gap: '25px', width: '400px', height: '200px' }}>
                    <h2 className="medium-title" style={{ color: '#EADBFE', fontSize: '35px' }}>
                        Zolbootbaatar Orosoo
                    </h2>
                    <div className="row-start">
                        <img src={phone} className={styles.icon} alt="" />
                        <div className="column" style={{ gap: '5px' }}>
                            <h2 className="medium-title" style={{ color: '#EADBFE' }}>
                                Phone
                            </h2>
                            <p className="text" style={{ color: '#EADBFE' }}>
                                +976 89257078
                            </p>
                        </div>
                    </div>
                    <div className="row-start">
                        <img src={mail} className={styles.icon} alt="" />
                        <div className="column" style={{ gap: '5px' }}>
                            <h2 className="medium-title" style={{ color: '#EADBFE' }}>
                                Mail
                            </h2>
                            <p className="text" style={{ color: '#EADBFE' }}>
                                ozolbootbaatar@gmail.com
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.infocard} style={{ gap: '25px', width: '400px', height: '200px' }}>
                    <h2 className="medium-title" style={{ color: '#EADBFE', fontSize: '35px' }}>
                        About Company
                    </h2>
                    <div className="row-start">
                        <h2 className="medium-title" style={{ color: '#EADBFE', fontSize: '20px' }}>
                            Company Name
                        </h2>
                        <p className="text" style={{ color: '#EADBFE' }}>
                            Matrix solutions labs
                        </p>
                    </div>
                    <div className="row-start">
                        <h2 className="medium-title" style={{ color: '#EADBFE', fontSize: '20px' }}>
                            Organization type
                        </h2>
                        <p className="text" style={{ color: '#EADBFE', width: '150px' }}>
                            Technology, Web developing
                        </p>
                    </div>
                </div>
            </div>
            <div className='row' style={{ alignItems: 'start', justifyContent: 'space-evenly' }}>
                <div className={styles.productioncard}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '20px' }}>
                        <h2>Active Site</h2>
                        <h3>Template.mn</h3>
                    </div>
                    <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS1I9-nPLUdq9N3v_ubV_qvSJ8DN_y9pb3ODQ&s" alt="" />
                </div>
                <div className={styles.productioncard} style={{ height: '47vh', justifyContent: 'space-between' }}>
                    <h2>List of QR name cards</h2>
                    <div className="divider" style={{ width: '30vw' }}></div>
                    <ul>
                        <li>
                            <p>
                                <GoDotFill size={15} />
                                Enerel
                            </p>
                            <button className={styles.button}>Edit</button>
                            <button className={styles.button}>Delete</button>
                        </li>

                        <li>
                            <p>
                                <GoDotFill size={15} />
                                Zolboot
                            </p>
                            <button className={styles.button}>Edit</button>
                            <button className={styles.button}>Delete</button>
                        </li>

                        <li>
                            <p>
                                <GoDotFill size={15} />
                                Moonie
                            </p>
                            <button className={styles.button}>Edit</button>
                            <button className={styles.button}>Delete</button>
                        </li>
                    </ul>
                    <div className='row' style={{ alignItems: 'end', justifyContent: 'end', width: '30vw' }}>
                        <button className={styles.button} style={{ marginBottom: '50px' }}>
                            <IoAddCircleOutline size={25} /> Add
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile