import React, { useState } from 'react';
import styles from './Sidebar.module.css';
import { MdUnfoldMore } from "react-icons/md";
import { AiOutlineShopping } from "react-icons/ai";
import { Link, json } from 'react-router-dom';
import { LuUserCircle } from "react-icons/lu";
import LoginModal from '../../Modal/Login/LoginModal';
import { RxCross1 } from "react-icons/rx";
import { CiMenuBurger } from "react-icons/ci";
import { TfiLayoutSlider } from "react-icons/tfi";
import Signup from '../../Modal/Signup/Signup';

const Sidebar = ({ categories }) => {
    const [link, setLink] = useState(0);
    const [modal, setModal] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [signup, setSignup] = useState(false);

    const user = JSON.parse(window.localStorage.getItem('user'));

    console.log(user);

    const closeModal = () => {
        setModal(false);
    };

    const closeModalSignUp = () => {
        setSignup(false);
    }

    return (
        <>
            {modal && <LoginModal closeModal={closeModal} setSignup={setSignup} />}
            {signup && <Signup closeModalSignUp={closeModalSignUp} />}
            {sidebarOpen || window.innerWidth > 768 ?
                <div style={{ zIndex: 10 }} className={styles.container}>

                    <RxCross1 onClick={() => setSidebarOpen(false)} className={styles.closeicon} />

                    {user ?
                        <div className={styles.user_card}>
                            <img className={styles.profile} src={'https://template.templateapi.xyz/uploads/' + user.cover} alt="" />
                            <div className='column'>
                                <p className='mini-bold-text'>{user.name}</p>
                                <p className='mini-grey-text'>{user.email}</p>
                            </div>
                            <MdUnfoldMore size={25} color='#333' />
                        </div>
                        :
                        <div className={styles.user_card} onClick={() => setModal(true)}
                            style={{ justifyContent: 'center' }}>
                            <LuUserCircle size={30} />
                            <div className='column'>
                                <p className='mini-bold-text'>Нэвтрэх</p>
                            </div>
                        </div>
                    }
                    <div className={styles.items}>
                        <Link to={'/'} onClick={() => setLink(0)}
                            className={link === 0 ? styles.item : styles.unSelectItem}
                        >
                            <TfiLayoutSlider size={25} />
                            Нүүр хуудас
                        </Link>
                        {categories.map((e, index) => (
                            <Link to={'/categorydetial/' + e._id} onClick={() => setLink(index + 2)}
                                className={link === index + 2 ? styles.item : styles.unSelectItem}>
                                <AiOutlineShopping size={25} />
                                {e.name}
                            </Link>
                        ))}
                    </div>
                </div> : <CiMenuBurger style={{ zIndex: 10 }} className={styles.icon} onClick={() => setSidebarOpen(true)} />}
        </>
    );
};

export default Sidebar;
