import React from 'react'
import styles from './Home.module.css'
import { MdWeb } from "react-icons/md";
import Navbar from '../../Components/Navbar/Navbar';
import WebCard from '../../Utills/WecCard/WebCard';

import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Link } from 'react-router-dom';

const Home = ({ categories, templates, sliders }) => {

    const handleSlideChange = () => {
        console.log('slide change');
    };

    return (
        <div style={{ background: '#fff' }}>
            <Navbar />
            <div className='container'>
                {window.innerWidth > 768 ? <div className={styles.categories}>
                    {categories.map((e) => (
                        <a href={'#' + e.name} className="grey-bg-text">
                            {e.name}
                        </a>
                    ))}
                </div> : null}
                <div className="slider">

                    <Swiper
                        style={{
                            '--swiper-navigation-color': '#fff',
                            '--swiper-pagination-color': '#fff',
                        }}
                        modules={[Autoplay, Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={0}
                        slidesPerView={1}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: true,
                        }}
                        className='swiper'
                        navigation
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={handleSlideChange}
                    >
                        {sliders.map((e) => (
                            <SwiperSlide className='swiper'>
                                <Link className='swiper'>
                                    <img src={'https://template.templateapi.xyz/uploads/' + e.cover} alt="" />
                                    <p></p>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    {/* <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTibzXPzcBshZLTNzKYlG33k7i839JeMOxKIH1eMUn5SA&s" alt="" /> */}
                </div>
                <div className={styles.categories_list}>
                    {categories.map((e) => (
                        <div id={e.name} className={styles.category}>
                            <div className={styles.category_title}>
                                <MdWeb color='#939393' size={30} />
                                <h3>{e.name}</h3>
                                <div style={{ width: window.innerWidth > 768 ? '65vw' : '35vw' }} className="divider"></div>
                            </div>
                            {templates
                                .filter((el) => el.category === e._id)
                                .map((e) => (
                                    <div className={styles.category_items}>
                                        <WebCard title={e.name} id={e._id}
                                            img={"https://template.templateapi.xyz/uploads/" + e.cover}
                                        />
                                    </div>
                                ))}
                        </div>
                    ))}
                </div>
            </div>
        </div >
    )
}

export default Home
