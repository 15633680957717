import React from 'react'
import { Link } from 'react-router-dom'
import styles from './WebCard.module.css';

const WebCard = ({ title, img, id }) => {
    return (
        <Link to={'/webdetial/' + id} className={styles.category_item}>
            <img src={img} alt="" />
            <div style={{ width: '300px' }} className="divider"></div>
            <p>{title}</p>
        </Link>
    )
}

export default WebCard
