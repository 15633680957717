import React from 'react'
import styles from './WebDetial.module.css'
import Navbar from '../../../Components/Navbar/Navbar'
import WebCard from '../../../Utills/WecCard/WebCard'
import { useParams } from 'react-router-dom'
import PlanCard from '../../../Utills/PlanCard/PlanCard'
import BrigthPlanCard from '../../../Utills/PlanCard/BrigthPlanCard'

const WebDetial = ({ templates }) => {
    const { id } = useParams();
    const template = templates.find((e) => e._id === id);
    return (
        <div style={{ background: '#f0f0f0' }}>
            <Navbar />
            <div className="container" style={{ gap: '70px' }}>
                <div className={styles.header}>
                    <div className={styles.header_item}>
                        <img src={'https://template.templateapi.xyz/uploads/' + template?.cover} alt="" />
                    </div>
                    <div className={styles.header_item}>
                        <div className="column">
                            <h2>{template?.name}</h2>
                            <p>Web Type</p>
                        </div>
                        <div className="row">
                            <div className='column' style={{ gap: 0 }}>
                                <p>Үндсэн зарах үнэ:</p>
                                <h2 style={{ fontSize: '45px', color: '#58c466' }}>{Intl.NumberFormat('en-us').format(template?.price)}₮</h2>
                            </div>
                            <div style={{ gap: 0 }} className="column">
                                <p style={{ fontSize: '18px', color: '#838383', textDecoration: 'line-through' }}>{Intl.NumberFormat('en-us').format(template?.oldPrice)}₮</p>
                                <p className='bold-text' style={{ fontSize: '18px', color: '#FFBF00' }}>{template?.discount}%</p>
                            </div>
                        </div>
                        <div className="column" style={{ gap: '30px' }}>
                            <a className='white-button' href={template?.test}>Тест хувилбарыг хэрэглэх</a>
                            <a className='button'>Захиалга өгөх</a>
                        </div>
                    </div>
                </div>
                <div className={window.innerWidth > 720 ? "row" : 'column'}>
                    <PlanCard month={'1'} price={'70,000'} unit={2} />
                    <BrigthPlanCard month={'12'} price={'50,000'} unit={8} domain={true} analytics={true} />
                    <PlanCard month={'6'} price={'60,000'} unit={8} domain={true} />
                </div>
                <div className={styles.body}>
                    <h3>Тайлбар</h3>
                    <div className="divider" style={{ width: '75vw' }}></div>
                    <p>
                        {template?.description}
                    </p>
                </div>
                <div className={styles.body}>
                    <div className="row">
                        <h3>Бусад загварууд</h3>
                        <div className="divider" style={{ width: '60vw' }}></div>
                    </div>
                    <div className='overflow-x'>
                        {templates
                            .filter((e) => e._id !== id)
                            .map((e) => (
                                <WebCard title={e.name} id={e._id}
                                    img={'https://template.templateapi.xyz/uploads/' + e.cover}
                                />

                            ))}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default WebDetial
